import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeading"
import ProductCard from "../components/ProductCard"
import styled from "styled-components"

import Xs17PaImage from "../images/xs-standard-17d-pa-w260.jpg"
import Xs20PaImage from "../images/xs-standard-20d-padassist-w260.jpg"
import Xs17TrImage from "../images/xs-standard-17d-traction-w260.jpg"
import Xs20TrImage from "../images/xs-standard-20d-traction-w260.jpg"
import XsOrbitalImage from "../images/xs-standard-20o-w260.jpg"

import IndexSection3 from "../components/IndexSection3"

import SEO from "../components/seo"
import Helmet from "react-helmet"

const MpageStyles = styled.div`
  #product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const XsStandardSeriesPage = () => {
  return (
    <>
      <SEO title="Compact Walk Behind Floor Scrubbers | Timberline Cleaning Equipment" />
      <Helmet>
        {/* <title>
          Medium Walk Behind Floor Scrubbers | Timberline Cleaning Equipment
        </title> */}
        <description>
          Designed on top of a rugged steel frame, the XS-Series is the right
          size for close quarter cleaning.
        </description>
      </Helmet>
      <MainLayout>
        <PageHeading
          title="Compact Walk Behind Floor Scrubbers"
          subHeading="XS-Standard"
          description=" Designed on top of a rugged steel frame, the XS-Series is the right
          size for close quarter cleaning."
          path="17 - 20in (43 - 51cm)"
          tank="Sol: 10gal (38l) Rec: 10gal (38l)"
          time="Up to 31,496 sqft. Per Charge"
          title1="Cleaning Path:"
          title2="Tank Capacity:"
          title3="Run Time:"
        />
        <MpageStyles>
          <div id="product-container">
            <ProductCard
              image={Xs17PaImage}
              titleSize="20px"
              title="XS17 Standard Disk Pad Assist"
              description="Cleaning Path - 17 in / 43 cm"
              link="/products/xs17-standard-disk-pad-assist-scrubber"
            />
            <ProductCard
              image={XsOrbitalImage}
              titleSize="20px"
              title="XS20 Orbital Traction"
              description="Cleaning Path - 20 in / 51 cm"
              link="/products/xs20-standard-orbital-scrubber"
            />
            <ProductCard
              image={Xs20PaImage}
              titleSize="20px"
              title="XS20 Standard Disk Pad Assist"
              description="Cleaning Path - 20 in / 51 cm"
              link="/products/xs20-standard-disk-pad-assist-scrubber"
            />
            <ProductCard
              image={Xs17TrImage}
              titleSize="20px"
              title="XS17 Standard Disk Traction "
              description="Cleaning Path - 17 in / 43 cm "
              link="/products/xs17-standard-disk-traction-scrubber"
            />
            <ProductCard
              image={Xs20TrImage}
              titleSize="20px"
              title="XS20 Standard Disk Traction"
              description="Cleaning Path - 20 in / 51 cm"
              link="/products/xs20-standard-disk-traction-scrubber"
            />
          </div>
        </MpageStyles>
        <IndexSection3 title="Request a Free Demo at your Facility" />
      </MainLayout>
    </>
  )
}

export default XsStandardSeriesPage
